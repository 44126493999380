import * as ProductGrpc from "@libs/proto/core/product/v1/product";
import { google } from "@libs/proto/google/protobuf/timestamp";

export interface ProductParams {
  productInfoId?: string;
  cpfCnpjEmissor?: string;
  status?: ProductGrpc.core.product.v1.Status[];
  createdAtStart?: google.protobuf.Timestamp;
  createdAtEnd?: google.protobuf.Timestamp;
  dueDateStart?: string;
  dueDateEnd?: string;
  originId?: string;
  minValue?: number;
  maxValue?: number;
  page?: number;
  itemsPerPage?: number;
  tokenizable: boolean;
  isFinished?: any;
  category?: string;
  auctionId?: string;
}

export interface Product {
  id?: string;
  name?: string;
  statusName?: string;
  transactions?: string[];
  productInfo?: {
    displayName?: string;
    id?: string;
    smartContractAddress?: string;
    transactions?: TransactionBackend[];
  };
  originId?: string;
  originName?: string;
  productInfoId?: string;
  lastError?: string;
  dataPublicacao?: any;
  displayName?: string;
  category?: Category;
  sections?: Section[];
  enabled?: boolean;
  commercialName?: string;
  productSettings?: any[];
  signatories?: any[];
  status?: number;
  tokenId?: string;
  values?: {
    endereco_sacado?: string;
    numero_nota_fiscal?: string;
    valor_nominal?: string;
    codigo_ibge_municipio_sacado?: string;
    cpf_cnpj_emissor?: string;
    cpf_cnpj_sacado?: string;
    data_emissao?: string;
    data_vencimento?: string;
    endereco_emissor?: string;
    id_fatura?: string;
    numero_duplicata?: string;
    razao_social_emissor?: string;
    razao_social_sacado?: string;
    serie_nota_fiscal?: string;
    valor_aquisicao?: string;
  };
  //frontend
  form?: any;
}

export interface TransactionBackend {
  externalServiceId?: string;
  id?: string;
  signatures: any[];
  status?: number;
  transactionType?: number;
}

export interface Category {
  name: string;
  displayName: string;
}

export interface Section {
  id?: string;
  title?: string;
  rows?: Row[];

  //only frontend
  allowEdit?: boolean;
}

export interface Row {
  inputs?: InputType[];
}

export interface InputType {
  //Required
  id?: string;
  displayName?: string;
  type?: typeOfInput;

  //Optionals
  required?: boolean;
  name?: string;
  description?: string;
  options?: { label?: string; value?: string }[];
  stringValue?: string;
  doubleValue?: number;
  order?: number;
  parameterKind?: number;
  field?: string;
  placeholder?: string;
  defaultValue?: any;
  readOnly?: boolean;
  min?: any;
  max?: any;
  //only Frontend
  shouldRemove?: boolean;
  copy?: boolean;
  copyMessage?: string;
}

export interface Template {
  id?: string;
  displayName?: string;
  sections?: Section[];
}

export interface ProductInfo {
  id?: string;
  name?: string;
  displayName?: string;
}

export type typeOfInput =
  | "unspecified"
  | "string"
  | "number"
  | "date"
  | "tenor"
  | "object"
  | "money"
  | "hour"
  | "cpf"
  | "cnpj"
  | "options_multiple"
  | "options_single"
  | "number_decimal"
  | "cep"
  | "phone"
  | "email"
  | "url"
  | "checkbox"
  | "file"
  | "toggle"
  | "password"
  | "document"
  | "face_value"
  | "";

enum ParameterType {
  PARAMETER_TYPE_UNSPECIFIED = 0,
  PARAMETER_TYPE_STRING = 1,
  PARAMETER_TYPE_NUMBER = 2,
  PARAMETER_TYPE_DATE = 3,
  PARAMETER_TYPE_TENOR = 4,
  PARAMETER_TYPE_OBJECT = 5,
  PARAMETER_TYPE_MONEY = 6,
  PARAMETER_TYPE_HOUR = 7,
  PARAMETER_TYPE_CPF = 8,
  PARAMETER_TYPE_CNPJ = 9,
  PARAMETER_TYPE_OPTIONS_MULTIPLE = 10,
  PARAMETER_TYPE_OPTIONS_SINGLE = 11,
  PARAMETER_TYPE_NUMBER_DECIMAL = 12,
  PARAMETER_TYPE_CEP = 13,
  PARAMETER_TYPE_PHONE = 14,
  PARAMETER_TYPE_EMAIL = 15,
  PARAMETER_TYPE_URL = 16,
  PARAMETER_TYPE_CHECKBOX = 17,
  PARAMETER_TYPE_FILE = 18,
  PARAMETER_TYPE_TOGGLE = 19,
  PARAMETER_TYPE_DOCUMENT = 20,
  PARAMETER_TYPE_FACE_VALUE = 21,
}
export const ParameterTypeToString: { [key in ParameterType]: typeOfInput } = {
  [ParameterType.PARAMETER_TYPE_UNSPECIFIED]: "unspecified",
  [ParameterType.PARAMETER_TYPE_STRING]: "string",
  [ParameterType.PARAMETER_TYPE_NUMBER]: "number",
  [ParameterType.PARAMETER_TYPE_DATE]: "date",
  [ParameterType.PARAMETER_TYPE_TENOR]: "tenor",
  [ParameterType.PARAMETER_TYPE_OBJECT]: "object",
  [ParameterType.PARAMETER_TYPE_MONEY]: "money",
  [ParameterType.PARAMETER_TYPE_HOUR]: "hour",
  [ParameterType.PARAMETER_TYPE_CPF]: "cpf",
  [ParameterType.PARAMETER_TYPE_CNPJ]: "cnpj",
  [ParameterType.PARAMETER_TYPE_OPTIONS_MULTIPLE]: "options_multiple",
  [ParameterType.PARAMETER_TYPE_OPTIONS_SINGLE]: "options_single",
  [ParameterType.PARAMETER_TYPE_NUMBER_DECIMAL]: "number_decimal",
  [ParameterType.PARAMETER_TYPE_CEP]: "cep",
  [ParameterType.PARAMETER_TYPE_PHONE]: "phone",
  [ParameterType.PARAMETER_TYPE_EMAIL]: "email",
  [ParameterType.PARAMETER_TYPE_URL]: "url",
  [ParameterType.PARAMETER_TYPE_CHECKBOX]: "checkbox",
  [ParameterType.PARAMETER_TYPE_FILE]: "file",
  [ParameterType.PARAMETER_TYPE_TOGGLE]: "toggle",
  [ParameterType.PARAMETER_TYPE_DOCUMENT]: "document",
  [ParameterType.PARAMETER_TYPE_FACE_VALUE]: "face_value",
};

export const getStatusName = (statusNumber: number): string => {
  switch (statusNumber) {
    case ProductGrpc.core.product.v1.Status.PRODUCT_STATUS_UNSPECIFIED:
      return "Outro";
    case ProductGrpc.core.product.v1.Status.PRODUCT_STATUS_PENDING:
      return "Cadastrado";
    case ProductGrpc.core.product.v1.Status.PRODUCT_STATUS_PUBLISHED:
      return "Publicado";
    case ProductGrpc.core.product.v1.Status.PRODUCT_STATUS_EXPIRED:
      return "Vencido";
    case ProductGrpc.core.product.v1.Status.PRODUCT_STATUS_REJECTED:
      return "Rejeitado";
    case ProductGrpc.core.product.v1.Status.PRODUCT_STATUS_PUBLISHING:
      return "Publicando";
    case ProductGrpc.core.product.v1.Status.PRODUCT_STATUS_BURNING:
      return "Queimando";
    case ProductGrpc.core.product.v1.Status.PRODUCT_STATUS_BURNED:
      return "Queimado";
    case ProductGrpc.core.product.v1.Status.PRODUCT_STATUS_EXPIRING:
      return "Vencendo";
    case ProductGrpc.core.product.v1.Status.PRODUCT_STATUS_TELEPORTING:
      return "Publicando";
    case ProductGrpc.core.product.v1.Status.PRODUCT_STATUS_TELEPORTED:
      return "Publicado";
    case ProductGrpc.core.product.v1.Status.PRODUCT_STATUS_TRANSFERRING:
      return "Transferindo";
    case ProductGrpc.core.product.v1.Status.PRODUCT_STATUS_TRANSFERRED:
      return "Transferido";
    default:
      return "Outro";
  }
};

export enum TransactionType {
  PRODUCT, //Assinar e publicar
  TRANSFER, //Transferir
}

export type ActionType = "publish" | "burn" | "reprocess";
