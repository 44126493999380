/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 4.25.2
 * source: core/billing/v1/billing.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./../../../google/protobuf/timestamp";
import * as dependency_2 from "./../../transaction/v1/transaction";
import * as pb_1 from "google-protobuf";
export namespace core.billing.v1 {
    export enum TableFeePeriod {
        UNSPECIFIED_TABLE_FEE_PERIOD = 0,
        ANNUALLY_TABLE_FEE_PERIOD = 1,
        SEMI_ANNUALLY_TABLE_FEE_PERIOD = 2,
        MONTHLY_TABLE_FEE_PERIOD = 3,
        BIWEEKLY_TABLE_FEE = 4,
        WEEKLY_TABLE_FEE = 5
    }
    export enum PricingTypes {
        UNSPECIFIED_TYPE = 0,
        BLOCKCHAIN_MINT_TYPE = 1,
        BLOCKCHAIN_WALLET_TYPE = 2,
        BLOCKCHAIN_RANGE_TYPE = 3
    }
    export class Ticket extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
            createdAt?: dependency_1.google.protobuf.Timestamp;
            updatedAt?: dependency_1.google.protobuf.Timestamp;
            transaction?: dependency_2.core.transaction.v1.Transaction;
            value?: number;
            companyId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("createdAt" in data && data.createdAt != undefined) {
                    this.createdAt = data.createdAt;
                }
                if ("updatedAt" in data && data.updatedAt != undefined) {
                    this.updatedAt = data.updatedAt;
                }
                if ("transaction" in data && data.transaction != undefined) {
                    this.transaction = data.transaction;
                }
                if ("value" in data && data.value != undefined) {
                    this.value = data.value;
                }
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get createdAt() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 2) as dependency_1.google.protobuf.Timestamp;
        }
        set createdAt(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get hasCreatedAt() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get updatedAt() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 3) as dependency_1.google.protobuf.Timestamp;
        }
        set updatedAt(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasUpdatedAt() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get transaction() {
            return pb_1.Message.getWrapperField(this, dependency_2.core.transaction.v1.Transaction, 4) as dependency_2.core.transaction.v1.Transaction;
        }
        set transaction(value: dependency_2.core.transaction.v1.Transaction) {
            pb_1.Message.setWrapperField(this, 4, value);
        }
        get hasTransaction() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get value() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set value(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        static fromObject(data: {
            id?: string;
            createdAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            updatedAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            transaction?: ReturnType<typeof dependency_2.core.transaction.v1.Transaction.prototype.toObject>;
            value?: number;
            companyId?: string;
        }): Ticket {
            const message = new Ticket({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.createdAt != null) {
                message.createdAt = dependency_1.google.protobuf.Timestamp.fromObject(data.createdAt);
            }
            if (data.updatedAt != null) {
                message.updatedAt = dependency_1.google.protobuf.Timestamp.fromObject(data.updatedAt);
            }
            if (data.transaction != null) {
                message.transaction = dependency_2.core.transaction.v1.Transaction.fromObject(data.transaction);
            }
            if (data.value != null) {
                message.value = data.value;
            }
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                createdAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                updatedAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                transaction?: ReturnType<typeof dependency_2.core.transaction.v1.Transaction.prototype.toObject>;
                value?: number;
                companyId?: string;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.createdAt != null) {
                data.createdAt = this.createdAt.toObject();
            }
            if (this.updatedAt != null) {
                data.updatedAt = this.updatedAt.toObject();
            }
            if (this.transaction != null) {
                data.transaction = this.transaction.toObject();
            }
            if (this.value != null) {
                data.value = this.value;
            }
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.hasCreatedAt)
                writer.writeMessage(2, this.createdAt, () => this.createdAt.serialize(writer));
            if (this.hasUpdatedAt)
                writer.writeMessage(3, this.updatedAt, () => this.updatedAt.serialize(writer));
            if (this.hasTransaction)
                writer.writeMessage(4, this.transaction, () => this.transaction.serialize(writer));
            if (this.value != 0)
                writer.writeInt64(5, this.value);
            if (this.companyId.length)
                writer.writeString(6, this.companyId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Ticket {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Ticket();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.createdAt, () => message.createdAt = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 3:
                        reader.readMessage(message.updatedAt, () => message.updatedAt = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 4:
                        reader.readMessage(message.transaction, () => message.transaction = dependency_2.core.transaction.v1.Transaction.deserialize(reader));
                        break;
                    case 5:
                        message.value = reader.readInt64();
                        break;
                    case 6:
                        message.companyId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Ticket {
            return Ticket.deserialize(bytes);
        }
    }
    export class TableFee extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
            companyId?: string;
            createdAt?: dependency_1.google.protobuf.Timestamp;
            pricingList?: Map<string, PricingList>;
            tablePeriod?: TableFeePeriod;
            custom?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
                if ("createdAt" in data && data.createdAt != undefined) {
                    this.createdAt = data.createdAt;
                }
                if ("pricingList" in data && data.pricingList != undefined) {
                    this.pricingList = data.pricingList;
                }
                if ("tablePeriod" in data && data.tablePeriod != undefined) {
                    this.tablePeriod = data.tablePeriod;
                }
                if ("custom" in data && data.custom != undefined) {
                    this.custom = data.custom;
                }
            }
            if (!this.pricingList)
                this.pricingList = new Map();
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get createdAt() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 3) as dependency_1.google.protobuf.Timestamp;
        }
        set createdAt(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasCreatedAt() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get pricingList() {
            return pb_1.Message.getField(this, 4) as any as Map<string, PricingList>;
        }
        set pricingList(value: Map<string, PricingList>) {
            pb_1.Message.setField(this, 4, value as any);
        }
        get tablePeriod() {
            return pb_1.Message.getFieldWithDefault(this, 5, TableFeePeriod.UNSPECIFIED_TABLE_FEE_PERIOD) as TableFeePeriod;
        }
        set tablePeriod(value: TableFeePeriod) {
            pb_1.Message.setField(this, 5, value);
        }
        get custom() {
            return pb_1.Message.getFieldWithDefault(this, 6, false) as boolean;
        }
        set custom(value: boolean) {
            pb_1.Message.setField(this, 6, value);
        }
        static fromObject(data: {
            id?: string;
            companyId?: string;
            createdAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            pricingList?: {
                [key: string]: ReturnType<typeof PricingList.prototype.toObject>;
            };
            tablePeriod?: TableFeePeriod;
            custom?: boolean;
        }): TableFee {
            const message = new TableFee({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            if (data.createdAt != null) {
                message.createdAt = dependency_1.google.protobuf.Timestamp.fromObject(data.createdAt);
            }
            if (typeof data.pricingList == "object") {
                message.pricingList = new Map(Object.entries(data.pricingList).map(([key, value]) => [key, PricingList.fromObject(value)]));
            }
            if (data.tablePeriod != null) {
                message.tablePeriod = data.tablePeriod;
            }
            if (data.custom != null) {
                message.custom = data.custom;
            }
            return message;
        }
        toObject() {
            const data: {
                id?: string;
                companyId?: string;
                createdAt?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                pricingList?: {
                    [key: string]: ReturnType<typeof PricingList.prototype.toObject>;
                };
                tablePeriod?: TableFeePeriod;
                custom?: boolean;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            if (this.createdAt != null) {
                data.createdAt = this.createdAt.toObject();
            }
            if (this.pricingList != null) {
                data.pricingList = (Object.fromEntries)((Array.from)(this.pricingList).map(([key, value]) => [key, value.toObject()]));
            }
            if (this.tablePeriod != null) {
                data.tablePeriod = this.tablePeriod;
            }
            if (this.custom != null) {
                data.custom = this.custom;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.companyId.length)
                writer.writeString(2, this.companyId);
            if (this.hasCreatedAt)
                writer.writeMessage(3, this.createdAt, () => this.createdAt.serialize(writer));
            for (const [key, value] of this.pricingList) {
                writer.writeMessage(4, this.pricingList, () => {
                    writer.writeString(1, key);
                    writer.writeMessage(2, value, () => value.serialize(writer));
                });
            }
            if (this.tablePeriod != TableFeePeriod.UNSPECIFIED_TABLE_FEE_PERIOD)
                writer.writeEnum(5, this.tablePeriod);
            if (this.custom != false)
                writer.writeBool(6, this.custom);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TableFee {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TableFee();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        message.companyId = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.createdAt, () => message.createdAt = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 4:
                        reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.pricingList as any, reader, reader.readString, () => {
                            let value;
                            reader.readMessage(message, () => value = PricingList.deserialize(reader));
                            return value;
                        }));
                        break;
                    case 5:
                        message.tablePeriod = reader.readEnum();
                        break;
                    case 6:
                        message.custom = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): TableFee {
            return TableFee.deserialize(bytes);
        }
    }
    export class TicketBalance extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            companyId?: string;
            pricing?: Map<string, Pricing>;
            validity?: dependency_1.google.protobuf.Timestamp;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
                if ("pricing" in data && data.pricing != undefined) {
                    this.pricing = data.pricing;
                }
                if ("validity" in data && data.validity != undefined) {
                    this.validity = data.validity;
                }
            }
            if (!this.pricing)
                this.pricing = new Map();
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get pricing() {
            return pb_1.Message.getField(this, 2) as any as Map<string, Pricing>;
        }
        set pricing(value: Map<string, Pricing>) {
            pb_1.Message.setField(this, 2, value as any);
        }
        get validity() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 3) as dependency_1.google.protobuf.Timestamp;
        }
        set validity(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasValidity() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            companyId?: string;
            pricing?: {
                [key: string]: ReturnType<typeof Pricing.prototype.toObject>;
            };
            validity?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
        }): TicketBalance {
            const message = new TicketBalance({});
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            if (typeof data.pricing == "object") {
                message.pricing = new Map(Object.entries(data.pricing).map(([key, value]) => [key, Pricing.fromObject(value)]));
            }
            if (data.validity != null) {
                message.validity = dependency_1.google.protobuf.Timestamp.fromObject(data.validity);
            }
            return message;
        }
        toObject() {
            const data: {
                companyId?: string;
                pricing?: {
                    [key: string]: ReturnType<typeof Pricing.prototype.toObject>;
                };
                validity?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            } = {};
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            if (this.pricing != null) {
                data.pricing = (Object.fromEntries)((Array.from)(this.pricing).map(([key, value]) => [key, value.toObject()]));
            }
            if (this.validity != null) {
                data.validity = this.validity.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.companyId.length)
                writer.writeString(1, this.companyId);
            for (const [key, value] of this.pricing) {
                writer.writeMessage(2, this.pricing, () => {
                    writer.writeString(1, key);
                    writer.writeMessage(2, value, () => value.serialize(writer));
                });
            }
            if (this.hasValidity)
                writer.writeMessage(3, this.validity, () => this.validity.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TicketBalance {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TicketBalance();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.companyId = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.pricing as any, reader, reader.readString, () => {
                            let value;
                            reader.readMessage(message, () => value = Pricing.deserialize(reader));
                            return value;
                        }));
                        break;
                    case 3:
                        reader.readMessage(message.validity, () => message.validity = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): TicketBalance {
            return TicketBalance.deserialize(bytes);
        }
    }
    export class PricingList extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            pricing?: Pricing[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("pricing" in data && data.pricing != undefined) {
                    this.pricing = data.pricing;
                }
            }
        }
        get pricing() {
            return pb_1.Message.getRepeatedWrapperField(this, Pricing, 1) as Pricing[];
        }
        set pricing(value: Pricing[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data: {
            pricing?: ReturnType<typeof Pricing.prototype.toObject>[];
        }): PricingList {
            const message = new PricingList({});
            if (data.pricing != null) {
                message.pricing = data.pricing.map(item => Pricing.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                pricing?: ReturnType<typeof Pricing.prototype.toObject>[];
            } = {};
            if (this.pricing != null) {
                data.pricing = this.pricing.map((item: Pricing) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.pricing.length)
                writer.writeRepeatedMessage(1, this.pricing, (item: Pricing) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): PricingList {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new PricingList();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.pricing, () => pb_1.Message.addToRepeatedWrapperField(message, 1, Pricing.deserialize(reader), Pricing));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): PricingList {
            return PricingList.deserialize(bytes);
        }
    }
    export class Pricing extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            min?: number;
            max?: number;
            value?: number;
            registeredValue?: number;
            discountPercent?: number;
            quantity?: number;
            packagePrice?: number;
            spent?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("min" in data && data.min != undefined) {
                    this.min = data.min;
                }
                if ("max" in data && data.max != undefined) {
                    this.max = data.max;
                }
                if ("value" in data && data.value != undefined) {
                    this.value = data.value;
                }
                if ("registeredValue" in data && data.registeredValue != undefined) {
                    this.registeredValue = data.registeredValue;
                }
                if ("discountPercent" in data && data.discountPercent != undefined) {
                    this.discountPercent = data.discountPercent;
                }
                if ("quantity" in data && data.quantity != undefined) {
                    this.quantity = data.quantity;
                }
                if ("packagePrice" in data && data.packagePrice != undefined) {
                    this.packagePrice = data.packagePrice;
                }
                if ("spent" in data && data.spent != undefined) {
                    this.spent = data.spent;
                }
            }
        }
        get min() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set min(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get max() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set max(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get value() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set value(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get registeredValue() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set registeredValue(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get discountPercent() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set discountPercent(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get quantity() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set quantity(value: number) {
            pb_1.Message.setField(this, 6, value);
        }
        get packagePrice() {
            return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
        }
        set packagePrice(value: number) {
            pb_1.Message.setField(this, 7, value);
        }
        get spent() {
            return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
        }
        set spent(value: number) {
            pb_1.Message.setField(this, 8, value);
        }
        static fromObject(data: {
            min?: number;
            max?: number;
            value?: number;
            registeredValue?: number;
            discountPercent?: number;
            quantity?: number;
            packagePrice?: number;
            spent?: number;
        }): Pricing {
            const message = new Pricing({});
            if (data.min != null) {
                message.min = data.min;
            }
            if (data.max != null) {
                message.max = data.max;
            }
            if (data.value != null) {
                message.value = data.value;
            }
            if (data.registeredValue != null) {
                message.registeredValue = data.registeredValue;
            }
            if (data.discountPercent != null) {
                message.discountPercent = data.discountPercent;
            }
            if (data.quantity != null) {
                message.quantity = data.quantity;
            }
            if (data.packagePrice != null) {
                message.packagePrice = data.packagePrice;
            }
            if (data.spent != null) {
                message.spent = data.spent;
            }
            return message;
        }
        toObject() {
            const data: {
                min?: number;
                max?: number;
                value?: number;
                registeredValue?: number;
                discountPercent?: number;
                quantity?: number;
                packagePrice?: number;
                spent?: number;
            } = {};
            if (this.min != null) {
                data.min = this.min;
            }
            if (this.max != null) {
                data.max = this.max;
            }
            if (this.value != null) {
                data.value = this.value;
            }
            if (this.registeredValue != null) {
                data.registeredValue = this.registeredValue;
            }
            if (this.discountPercent != null) {
                data.discountPercent = this.discountPercent;
            }
            if (this.quantity != null) {
                data.quantity = this.quantity;
            }
            if (this.packagePrice != null) {
                data.packagePrice = this.packagePrice;
            }
            if (this.spent != null) {
                data.spent = this.spent;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.min != 0)
                writer.writeInt64(1, this.min);
            if (this.max != 0)
                writer.writeInt64(2, this.max);
            if (this.value != 0)
                writer.writeInt64(3, this.value);
            if (this.registeredValue != 0)
                writer.writeInt64(4, this.registeredValue);
            if (this.discountPercent != 0)
                writer.writeInt64(5, this.discountPercent);
            if (this.quantity != 0)
                writer.writeInt64(6, this.quantity);
            if (this.packagePrice != 0)
                writer.writeInt64(7, this.packagePrice);
            if (this.spent != 0)
                writer.writeInt64(8, this.spent);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Pricing {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Pricing();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.min = reader.readInt64();
                        break;
                    case 2:
                        message.max = reader.readInt64();
                        break;
                    case 3:
                        message.value = reader.readInt64();
                        break;
                    case 4:
                        message.registeredValue = reader.readInt64();
                        break;
                    case 5:
                        message.discountPercent = reader.readInt64();
                        break;
                    case 6:
                        message.quantity = reader.readInt64();
                        break;
                    case 7:
                        message.packagePrice = reader.readInt64();
                        break;
                    case 8:
                        message.spent = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Pricing {
            return Pricing.deserialize(bytes);
        }
    }
    export class TicketSummary extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            tickets?: Ticket[];
            transactionsValue?: number;
            taxesValue?: number;
            summaryRecords?: SummaryRecord[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1, 4], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("tickets" in data && data.tickets != undefined) {
                    this.tickets = data.tickets;
                }
                if ("transactionsValue" in data && data.transactionsValue != undefined) {
                    this.transactionsValue = data.transactionsValue;
                }
                if ("taxesValue" in data && data.taxesValue != undefined) {
                    this.taxesValue = data.taxesValue;
                }
                if ("summaryRecords" in data && data.summaryRecords != undefined) {
                    this.summaryRecords = data.summaryRecords;
                }
            }
        }
        get tickets() {
            return pb_1.Message.getRepeatedWrapperField(this, Ticket, 1) as Ticket[];
        }
        set tickets(value: Ticket[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        get transactionsValue() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set transactionsValue(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get taxesValue() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set taxesValue(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get summaryRecords() {
            return pb_1.Message.getRepeatedWrapperField(this, SummaryRecord, 4) as SummaryRecord[];
        }
        set summaryRecords(value: SummaryRecord[]) {
            pb_1.Message.setRepeatedWrapperField(this, 4, value);
        }
        static fromObject(data: {
            tickets?: ReturnType<typeof Ticket.prototype.toObject>[];
            transactionsValue?: number;
            taxesValue?: number;
            summaryRecords?: ReturnType<typeof SummaryRecord.prototype.toObject>[];
        }): TicketSummary {
            const message = new TicketSummary({});
            if (data.tickets != null) {
                message.tickets = data.tickets.map(item => Ticket.fromObject(item));
            }
            if (data.transactionsValue != null) {
                message.transactionsValue = data.transactionsValue;
            }
            if (data.taxesValue != null) {
                message.taxesValue = data.taxesValue;
            }
            if (data.summaryRecords != null) {
                message.summaryRecords = data.summaryRecords.map(item => SummaryRecord.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                tickets?: ReturnType<typeof Ticket.prototype.toObject>[];
                transactionsValue?: number;
                taxesValue?: number;
                summaryRecords?: ReturnType<typeof SummaryRecord.prototype.toObject>[];
            } = {};
            if (this.tickets != null) {
                data.tickets = this.tickets.map((item: Ticket) => item.toObject());
            }
            if (this.transactionsValue != null) {
                data.transactionsValue = this.transactionsValue;
            }
            if (this.taxesValue != null) {
                data.taxesValue = this.taxesValue;
            }
            if (this.summaryRecords != null) {
                data.summaryRecords = this.summaryRecords.map((item: SummaryRecord) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.tickets.length)
                writer.writeRepeatedMessage(1, this.tickets, (item: Ticket) => item.serialize(writer));
            if (this.transactionsValue != 0)
                writer.writeInt64(2, this.transactionsValue);
            if (this.taxesValue != 0)
                writer.writeInt64(3, this.taxesValue);
            if (this.summaryRecords.length)
                writer.writeRepeatedMessage(4, this.summaryRecords, (item: SummaryRecord) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TicketSummary {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TicketSummary();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.tickets, () => pb_1.Message.addToRepeatedWrapperField(message, 1, Ticket.deserialize(reader), Ticket));
                        break;
                    case 2:
                        message.transactionsValue = reader.readInt64();
                        break;
                    case 3:
                        message.taxesValue = reader.readInt64();
                        break;
                    case 4:
                        reader.readMessage(message.summaryRecords, () => pb_1.Message.addToRepeatedWrapperField(message, 4, SummaryRecord.deserialize(reader), SummaryRecord));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): TicketSummary {
            return TicketSummary.deserialize(bytes);
        }
    }
    export class SummaryRecord extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            transactionType?: dependency_2.core.transaction.v1.TransactionType;
            total?: number;
            value?: number;
            tax?: number;
            startDate?: dependency_1.google.protobuf.Timestamp;
            endDate?: dependency_1.google.protobuf.Timestamp;
            companyId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("transactionType" in data && data.transactionType != undefined) {
                    this.transactionType = data.transactionType;
                }
                if ("total" in data && data.total != undefined) {
                    this.total = data.total;
                }
                if ("value" in data && data.value != undefined) {
                    this.value = data.value;
                }
                if ("tax" in data && data.tax != undefined) {
                    this.tax = data.tax;
                }
                if ("startDate" in data && data.startDate != undefined) {
                    this.startDate = data.startDate;
                }
                if ("endDate" in data && data.endDate != undefined) {
                    this.endDate = data.endDate;
                }
                if ("companyId" in data && data.companyId != undefined) {
                    this.companyId = data.companyId;
                }
            }
        }
        get transactionType() {
            return pb_1.Message.getFieldWithDefault(this, 1, dependency_2.core.transaction.v1.TransactionType.UNSPECIFIED_TRANSACTION_TYPE) as dependency_2.core.transaction.v1.TransactionType;
        }
        set transactionType(value: dependency_2.core.transaction.v1.TransactionType) {
            pb_1.Message.setField(this, 1, value);
        }
        get total() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set total(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get value() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set value(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get tax() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set tax(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get startDate() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 5) as dependency_1.google.protobuf.Timestamp;
        }
        set startDate(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 5, value);
        }
        get hasStartDate() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get endDate() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 6) as dependency_1.google.protobuf.Timestamp;
        }
        set endDate(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 6, value);
        }
        get hasEndDate() {
            return pb_1.Message.getField(this, 6) != null;
        }
        get companyId() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set companyId(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        static fromObject(data: {
            transactionType?: dependency_2.core.transaction.v1.TransactionType;
            total?: number;
            value?: number;
            tax?: number;
            startDate?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            endDate?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            companyId?: string;
        }): SummaryRecord {
            const message = new SummaryRecord({});
            if (data.transactionType != null) {
                message.transactionType = data.transactionType;
            }
            if (data.total != null) {
                message.total = data.total;
            }
            if (data.value != null) {
                message.value = data.value;
            }
            if (data.tax != null) {
                message.tax = data.tax;
            }
            if (data.startDate != null) {
                message.startDate = dependency_1.google.protobuf.Timestamp.fromObject(data.startDate);
            }
            if (data.endDate != null) {
                message.endDate = dependency_1.google.protobuf.Timestamp.fromObject(data.endDate);
            }
            if (data.companyId != null) {
                message.companyId = data.companyId;
            }
            return message;
        }
        toObject() {
            const data: {
                transactionType?: dependency_2.core.transaction.v1.TransactionType;
                total?: number;
                value?: number;
                tax?: number;
                startDate?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                endDate?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                companyId?: string;
            } = {};
            if (this.transactionType != null) {
                data.transactionType = this.transactionType;
            }
            if (this.total != null) {
                data.total = this.total;
            }
            if (this.value != null) {
                data.value = this.value;
            }
            if (this.tax != null) {
                data.tax = this.tax;
            }
            if (this.startDate != null) {
                data.startDate = this.startDate.toObject();
            }
            if (this.endDate != null) {
                data.endDate = this.endDate.toObject();
            }
            if (this.companyId != null) {
                data.companyId = this.companyId;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.transactionType != dependency_2.core.transaction.v1.TransactionType.UNSPECIFIED_TRANSACTION_TYPE)
                writer.writeEnum(1, this.transactionType);
            if (this.total != 0)
                writer.writeInt64(2, this.total);
            if (this.value != 0)
                writer.writeInt64(3, this.value);
            if (this.tax != 0)
                writer.writeInt64(4, this.tax);
            if (this.hasStartDate)
                writer.writeMessage(5, this.startDate, () => this.startDate.serialize(writer));
            if (this.hasEndDate)
                writer.writeMessage(6, this.endDate, () => this.endDate.serialize(writer));
            if (this.companyId.length)
                writer.writeString(7, this.companyId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SummaryRecord {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SummaryRecord();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.transactionType = reader.readEnum();
                        break;
                    case 2:
                        message.total = reader.readInt64();
                        break;
                    case 3:
                        message.value = reader.readInt64();
                        break;
                    case 4:
                        message.tax = reader.readInt64();
                        break;
                    case 5:
                        reader.readMessage(message.startDate, () => message.startDate = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 6:
                        reader.readMessage(message.endDate, () => message.endDate = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 7:
                        message.companyId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): SummaryRecord {
            return SummaryRecord.deserialize(bytes);
        }
    }
    export class BillingReport extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            filename?: string;
            startDate?: dependency_1.google.protobuf.Timestamp;
            endDate?: dependency_1.google.protobuf.Timestamp;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("filename" in data && data.filename != undefined) {
                    this.filename = data.filename;
                }
                if ("startDate" in data && data.startDate != undefined) {
                    this.startDate = data.startDate;
                }
                if ("endDate" in data && data.endDate != undefined) {
                    this.endDate = data.endDate;
                }
            }
        }
        get filename() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set filename(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get startDate() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 2) as dependency_1.google.protobuf.Timestamp;
        }
        set startDate(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get hasStartDate() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get endDate() {
            return pb_1.Message.getWrapperField(this, dependency_1.google.protobuf.Timestamp, 3) as dependency_1.google.protobuf.Timestamp;
        }
        set endDate(value: dependency_1.google.protobuf.Timestamp) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasEndDate() {
            return pb_1.Message.getField(this, 3) != null;
        }
        static fromObject(data: {
            filename?: string;
            startDate?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            endDate?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
        }): BillingReport {
            const message = new BillingReport({});
            if (data.filename != null) {
                message.filename = data.filename;
            }
            if (data.startDate != null) {
                message.startDate = dependency_1.google.protobuf.Timestamp.fromObject(data.startDate);
            }
            if (data.endDate != null) {
                message.endDate = dependency_1.google.protobuf.Timestamp.fromObject(data.endDate);
            }
            return message;
        }
        toObject() {
            const data: {
                filename?: string;
                startDate?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
                endDate?: ReturnType<typeof dependency_1.google.protobuf.Timestamp.prototype.toObject>;
            } = {};
            if (this.filename != null) {
                data.filename = this.filename;
            }
            if (this.startDate != null) {
                data.startDate = this.startDate.toObject();
            }
            if (this.endDate != null) {
                data.endDate = this.endDate.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.filename.length)
                writer.writeString(1, this.filename);
            if (this.hasStartDate)
                writer.writeMessage(2, this.startDate, () => this.startDate.serialize(writer));
            if (this.hasEndDate)
                writer.writeMessage(3, this.endDate, () => this.endDate.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BillingReport {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BillingReport();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.filename = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.startDate, () => message.startDate = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    case 3:
                        reader.readMessage(message.endDate, () => message.endDate = dependency_1.google.protobuf.Timestamp.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): BillingReport {
            return BillingReport.deserialize(bytes);
        }
    }
}
